.dashboard-container {
  padding: 10px;
  justify-items: center;
  background-color: #f5f5f5; /* Background color for the overall dashboard */
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.shop-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-icon {
  color: red;
  font-size: 30px;
  margin-right: 5px;
  animation: bounce 2s infinite;
}

.shop-name {
  color: darkblue;
}

.animated-slogan {
  margin-top: 5px;
  text-align: center;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 2s ease-in-out;
}

.thumb-icon {
  margin-left: 5px;
  color: red;
  animation: bounce 2s infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  padding: 20px;
  
  justify-content: center;
}

.chart-paper {
  padding: 10px;
  margin: 5px;
  background: #539dd1; /* Light blue background for sales boxes */
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: 120px; /* Smaller fixed height for uniform size */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.large-table-container {
  padding: 10px;
  margin: 20px;
  background: #fff3e0; /* Light orange background for tables */
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: 240px; /* Increased height for larger tables */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.tables-container{
  margin-top: 20px;
  padding: 20px;
  justify-content:space-between ;
}

.chart-title,
.chart-total {
  text-align: center;
  font-size: 0.275rem; /* Smaller font size */
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.icon-red {
  color: blue;
}

.table-container {
  display: flex;
  flex-direction: column;
}

.cards-container{
  justify-content: center;

}

.table-title {
  margin-bottom: 10px;
  color: #37474f; /* Darker text color for titles */
}





