.sales-return-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto; /* Centers the form */
  }
  
  .return-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-gap: 20px; /* Gap between fields */
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  label {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  input, select {
    padding: 8px;
    font-size: 0.9rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  button.submit-button {
    padding: 8px 16px;
    font-size: 0.9rem;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    grid-column: 1 / span 2; /* Span button across two columns */
    justify-self: center; /* Center the button */
    transition: background-color 0.3s ease;
  }
  
  button.submit-button:hover {
    background-color: #45a049;
  }
  
  /* Single-column layout on smaller screens */
  @media (max-width: 768px) {
    .return-form {
      grid-template-columns: 1fr;
    }
  }
  