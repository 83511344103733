/* Center align the card content */
.shop-card {
    text-align: center;
  }
  
  /* Sans-serif font for the shop title */
  .shop-card-title {
    font-family: 'Sans-serif';
    color: teal;
  }
  
  /* Arial font for the rest of the text */
  .shop-card-text {
    font-family: "Gill Sans Extrabold", sans-serif;

  }
  
  /* Define colors for each shop name */
  .shop1 {
    color: teal;
  }
  
  .shop2 {
    color: maroon;
  }
  
  .shop3 {
    color: green;
  }
  
  .shop4 {
    color: lightgreen;
  }
  
  .shop5 {
    color: blue;
  }
  
  .shop6 {
    color: orange;
  }
  
  .shop7 {
    color: purple;
  }
  
  .shop8 {
    color: red;
  }
  