/* src/components/dashboard.css */
/* dashboard.css */
.dashboard-container {
  background-color: #f0f8ff;
  padding: 20px; /* Optional: Add some padding for better appearance */
}


.dashboard-header {
  text-align: center;
  margin-bottom: 30px;
}

.dashboard-header h1 {
  color: #06fa06;
  font-weight: bold;
}

.dashboard-header p {
  color: #6c757d;
  font-style: italic;
}

.shop-card {
  transition: transform 0.2s ease-in-out;
  background-color: white !important;
  color: rgb(5, 5, 5) !important;
  text-align: center;
  justify-content: center;
  font-size: 12px;
}

.shop-card:hover {
  transform: scale(1.05);
}


.sales-table {
  margin-top: 20px;
}
